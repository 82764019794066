.react-bootstrap-table {
    &>table>thead>tr>th {
        text-align: center;
        vertical-align: middle;
        &>.filter-label {
            width: 100%
        }
    }
    & td {
        text-align: center;
        vertical-align: middle;
        &>.d-flex {
            justify-content: center;
        }
    }
}

.lectureQuestion {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #ccc;
}

.checkbox-input {
    width: 1.5rem;
    height: 1.5rem;
}

.checkbox-label {
    font-size: 1rem;
    padding-top: 4px;
    padding-left: 5px;
}